#tableLeads_wrapper,
#tableCustomers_wrapper {
    margin-top: 15px;
    overflow-x: scroll;
}

#tableLeads_wrapper table tr.lead-is-late {
    background-color: rgba(255, 231, 50, .4);
}

#tableLeads_wrapper table th {
    max-width: 200px;
}

#tableLeads_wrapper table tbody tr.selected {
    background-color: #ccc;
}

.modalFormLoader {
    transform: scale(.5);
}

.modalFormLoader .sk-child {
    background-color: #002A68;
}

.lead-toolbar {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

.lead-toolbar .filters-container {
    display: flex;
    align-items: flex-end;
    justify-content: space-evenly;
}

.lead-toolbar .filters-container .filter {
    display: flex;
    align-items: flex-end;
    padding: 0 12px;
}

.lead-toolbar .filters-container .filter:last-of-type {
    padding-left: 0;
}

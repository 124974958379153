.app-loader-container {
    position: fixed;
    z-index: 99999;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
}

.app-loader-container .sk-three-bounce.app-loader {
    position: absolute;
    top: 50%;
    left: 50%;
}

.app-loader-container .sk-three-bounce.app-loader .sk-child {
    background-color: var(--color-info);
}

